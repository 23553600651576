import React from 'react';
import styles from '../styles.module.scss';
import {formatNumberAndPostfix} from '../../../helpers';
import {SplitStringWithLineBreak} from './SplitStringWithLineBreak';

interface MonitoringDataCardProps {
    num: number | undefined;
    text: string;
    column: number;
    percent?: number;
    postfix?: string;
}

export const MonitoringDataCard = (
    {
        text,
        num = 0,
        column,
        percent,
        postfix
    }: MonitoringDataCardProps
) => {
    return (
        <div className={styles.card} style={{width: `${(100 / column) - 2}%`}}>
            {percent ? <span className={styles.cardPercent}>{percent.toFixed(1)}%</span> : null}
            <span className={styles.cardText}>{formatNumberAndPostfix(num)} {postfix ? postfix : ''}</span>
            <SplitStringWithLineBreak text={text} className={styles.cardSubtext}/>
        </div>
    );
};
