import React from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import styles from './styles.module.scss';
import Footer from '../../components/footer';
import {useNavigate} from 'react-router-dom';
import {useLazyGetMainDataQuery} from './store/monitoring.api';
import {MonitoringDataCard, MonitoringFilter} from './components';
import {useMonitoringControl} from '../../hooks';
import AppLoader from '../../components/appLoader';

export const MonitoringMainData = () => {
    const navigate = useNavigate();
    const [getMainData, {data, isLoading}] = useLazyGetMainDataQuery();
    const {applyFilter, clearFilter} = useMonitoringControl({
        
        onFetch: ({params}) => {
            getMainData({params});
        }
    });
    return (
        <MainLayout>
            <Header breadCrumbs={'Мониторинг - Сегодня'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={true}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            {isLoading
                ? <AppLoader isFull={false}/> : (
                    <div className={styles.list}>
                        <MonitoringDataCard column={2}
                                            num={Math.round(data?.paySum as number)}
                                            text={'Выручка'}/>
                        <MonitoringDataCard column={2} num={data?.trx} text={'Чеков (TRX)'}/>
                        <MonitoringDataCard column={2} num={data?.at} text={'Сред чек (AT)'}/>
                        <MonitoringDataCard column={2} num={data?.adt} text={'Чеков/дн/тчк (ADT)'}/>
                    </div>
                )}

            <Footer/>
        </MainLayout>
    );
};
