import React from 'react';
import dayjs from 'dayjs';
import {useAppDispatch, useAppSelector} from '../../../hooks/app.hooks';
import {setReviewsFilter} from '../store/review.slice';
import {IFinancialFilter} from '../../../components/financialFilter/store/type';
import styles from '../../taskList/style.module.scss';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import {rangePresets} from '../../../components/taskFilter/TaskFilter';
import {DatePicker} from 'antd';

const {RangePicker} = DatePicker;

export const Filter = () => {
    const dispatch = useAppDispatch();
    const {filter} = useAppSelector(state => state.reviews);
    const handleChangeDate = (dateStrings: [string, string]) => {
        const formattedDates = dateStrings.map(date =>
            (dayjs(date, 'D MMM, YYYY').format('YYYY-MM-DD'))
        );

        const newKeys = filter.map(key => {
            if (key.type === 'Start') return {...key, value: formattedDates[0]};
            if (key.type === 'Finish') return {...key, value: formattedDates[1]};
            return key;
        });
        dispatch(setReviewsFilter(newKeys));
    };
    const handleChangeDate2 = (dateStrings: [string, string]) => {
        const formattedDates = dateStrings.map(date =>
            (dayjs(date, 'D MMM, YYYY').format('YYYY-MM-DD'))
        );

        const newKeys = filter.map(key => {
            if (key.type === 'Start2') return {...key, value: formattedDates[0]};
            if (key.type === 'Finish2') return {...key, value: formattedDates[1]};
            return key;
        });
        dispatch(setReviewsFilter(newKeys));
    };

    const dateStart = filter.find(k => k.type === 'Start') as IFinancialFilter;
    const dateFinish = filter.find(k => k.type === 'Finish') as IFinancialFilter;
    const dateStart2 = filter.find(k => k.type === 'Start2') as IFinancialFilter;
    const dateFinish2 = filter.find(k => k.type === 'Finish2') as IFinancialFilter;
    return (
        <div>
            <p className={styles.selectDateLabel}>Интервал 1</p>
            <RangePicker
                dropdownClassName="custom-range-picker-dropdown"
                className={styles.inputRange}
                locale={ru_RU}
                format="D MMM, YYYY"
                presets={rangePresets}
                value={[dayjs(dateStart.value as string, 'YYYY.MM.DD'), dayjs(dateFinish.value as string, 'YYYY.MM.DD')]}
                onChange={(dates, dateStrings) => handleChangeDate(dateStrings)}
                separator="-"
                allowClear={false}
            />
            <div className={styles.separator}/>
            <p className={styles.selectDateLabel}>Интервал 2</p>
            <RangePicker
                dropdownClassName="custom-range-picker-dropdown"
                className={styles.inputRange}
                locale={ru_RU}
                format="D MMM, YYYY"
                presets={rangePresets}
                value={[dayjs(dateStart2.value as string, 'YYYY.MM.DD'), dayjs(dateFinish2.value as string, 'YYYY.MM.DD')]}
                onChange={(dates, dateStrings) => handleChangeDate2(dateStrings)}
                separator="-"
                allowClear={false}
            />
        </div>
    );
};
