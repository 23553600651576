import React, {useEffect} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {useNavigate} from 'react-router-dom';
import {BackButton} from '../../components/backButton';
import {useAppSelector} from '../../hooks/app.hooks';
import {useLazyGetChartRatingsQuery, useLazyGetTableSourceQuery, useLazyGetTableTagQuery} from './store/review.api';
import {Chart, Filter, TableReview} from './components';
import styles from './style.module.scss';

export const ReviewCommon = () => {
    const navigate = useNavigate();
    const {filter} = useAppSelector(state => state?.reviews);

    const [getChart, {data}] = useLazyGetChartRatingsQuery();
    const [getTableSource, {data: table}] = useLazyGetTableSourceQuery();
    const [getTags, {data: tags}] = useLazyGetTableTagQuery();

    useEffect(() => {
        const params = filter?.map(item => `${item.type}=${item.value}`).join('&') ?? '';
        getChart({params});
        getTableSource({params});
        getTags({params});
    }, [filter]);

    const opt = data?.res.map(i => ({name: i.name, value: String(i.value)})) ?? [];

    return (
        <MainLayout>
            <Header breadCrumbs={'Cводные итоги'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.box}>
                <Filter/>
            </div>
            <div className={styles.box}>
                <p className={styles.boxLabel}>Оценки</p>
                <Chart chart={opt} handleClickByLegend={() => {
                }}/>
            </div>
            <div className={styles.box}>
                <p className={styles.boxLabel}>Источники</p>
                <TableReview table={table} handleSelect={state => navigate('/review-objects', {state})}/>
            </div>
            <div className={styles.box}>
                <p className={styles.boxLabel}>Теги</p>
                <TableReview table={tags} handleSelect={state => navigate('/review-objects', {state})}/>
                {/*<TableTags tags={tags}/>*/}
            </div>
        </MainLayout>
    );
};
