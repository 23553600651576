import React, {useEffect} from 'react';
import {useLazyGetAllQuestionsQuery} from './store/questions.api';
import {MainLayout, QuestionVariable} from '../../layouts';
import Header from '../../components/header';
import AppLoader from '../../components/appLoader';
import {useNavigate} from 'react-router-dom';
import {ListMenu} from './store/types';
import Footer from '../../components/footer';
import {typeFlow} from '../../store/types';

export const Home = () => {
    const navigate = useNavigate();
    const [getData, {data, isLoading}] = useLazyGetAllQuestionsQuery();

    useEffect(() => {
        getData();
    }, []);

    const handleJump = (item: ListMenu) => {
        switch (item.typeMob) {
            case typeFlow.ATS:
                return navigate(`/call-count/${item.idForm}`);
            case typeFlow.Checks:
                return navigate(`/check/${item.idForm}`);
            case typeFlow.RepBots:
                return navigate(`/check/${item.idForm}`);
            case typeFlow.Learn:
                return navigate(`/check/${item.idForm}`);
            case typeFlow.Tasks:
                return navigate(`/task-menu/${item.idForm}`);
            case typeFlow.Purchase:
                return navigate(`/purchase/${item.idForm}`);
            case typeFlow.Inventory:
                return navigate(`/select-location/${item.idForm}?from=${item.typeMob}`);
            case typeFlow.TimeControl:
                return navigate(`/time-control/${item.idForm}`);
            case typeFlow.Courier:
                return navigate(`/courier/${item.idForm}`);
            case typeFlow.Marketing:
                return navigate(`/marketing/${item.idForm}`);
            case typeFlow.CallCenter:
                return navigate(`/call-center/${item.idForm}`);
            case typeFlow.Products:
                return navigate(`/products/${item.idForm}`);
            case typeFlow.SOS:
                return navigate(`/select-location/${item.idForm}?from=${item.name}`, {
                    state: {
                        idParent: item.idParent,
                        path: '',
                    }
                });
            case typeFlow.Reports:
                return navigate(`/check-type/${item.idForm}`);
            case typeFlow.Information:
                return navigate(`/info-type/${item.idForm}`);
            case typeFlow.FinApp:
                return navigate(`/financial-menu/${item.idForm}`);
            case typeFlow.Monitor:
                return navigate(`/monitoring/${item.idForm}`);
            case typeFlow.Reviews:
                return navigate(`/review/${item.idForm}`);
        }
    };

    if (isLoading) {
        return <AppLoader isFull={true}/>;
    }
  
    return (
        <MainLayout>
            <Header breadCrumbs={''}/>
            {data?.status
                ? <QuestionVariable
                    iconWidth={64}
                    iconHeight={64}
                    renderData={data?.ListMenu}
                    handlePress={handleJump}/>
                : <p>{data?.message}</p>}
            <Footer/>
        </MainLayout>
    );
};
