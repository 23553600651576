import React, {useEffect} from 'react';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {ListMenu} from '../home/store/types';
import {extractValueFromString, getObjectByIdForm} from '../../helpers';
import {useGetFinancialCountMutation} from './state/financial.api';
import styles from './style.module.scss';
import SimpleButton from '../../components/simpleButton';
import {ImageLayout, MainLayout} from '../../layouts';
import {getCountColumn} from '../taskTypes/TaskMenu';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import Footer from '../../components/footer';
import {QuantityFinancial} from './state/types';
import {setFinancialQuantity} from './state/financial.slice';
import {FinancialFilter} from '../../components/financialFilter';
import {dateKeys, setClearFinancialFilter} from '../../components/financialFilter/store/financial.filter.slice';
import {FinancialFilterItemReq} from '../../components/financialFilter/store/type';
import {ChartUser} from '../../assets/customSvg';
import {themColors} from '../../hooks/useChangeTheme';

export const FinancialMenu = () => {
    const {idForm} = useParams<{ idForm: string }>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {data} = useGetAllQuestionsQuery();
    const [getCountFinancial] = useGetFinancialCountMutation();
    const {keys} = useAppSelector(state => state.financialFilter);
    const {quantity} = useAppSelector(state => state.financialMenu);
    const {theme} = useAppSelector(state => state.app);
    let buttonVariable: ListMenu | null = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));

    useEffect(() => {
        if (data) {
            const params = keys.map(key => `${FinancialFilterItemReq[key.type]}=${key.value}`).join('&');
            getCounts(params);
        }
    }, [data]);

    const getCounts = (params: string) => {

        const current = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));
        const sources = current?.children?.filter((i: ListMenu) => i.icon.type === 'fetch').map((i: ListMenu) => i.icon.source) ?? [];

        for (const source of sources) {
            getCountFinancial({path: `${source}&${params}`})
                .unwrap()
                .then((quantity) => {
                    const mode = extractValueFromString(source, 'showMode');
                    dispatch(setFinancialQuantity({
                        mode,
                        quan: quantity
                    }));
                })
                .catch(() => {
                });
        }
    };

    const handleFilterButton = (item: ListMenu) => {
        navigate(`/financial-list/${item.idForm}`);
    };

    const handleOtherButton = (item: ListMenu) => {
        if (item.typeMob?.toUpperCase() === 'SOS') {
            return navigate('/financial-create', {state: {idForm, isSos: true}});
        }

        navigate('/financial-create', {state: {idForm}});
    };

    const applyFilter = (isInit: boolean) => {
        isInit && dispatch(setClearFinancialFilter());

        const params = isInit
            ? dateKeys.map(key => `${FinancialFilterItemReq[key.type]}=${key.value}`).join('&')
            : keys.map(key => `${FinancialFilterItemReq[key.type]}=${key.value}`).join('&');

        getCounts(params);
    };

    const isFilterButtons = buttonVariable?.children.filter((i: ListMenu) => i.icon.type === 'fetch');
    const otherButtons = buttonVariable?.children.filter((i: ListMenu) => i.icon.type !== 'fetch');

    return (
        <MainLayout>
            <Header breadCrumbs={'Фин. заявки'}/>
            <BackButton text={'Назад'} onClick={() => navigate('/')}/>
            <FinancialFilter
                keys={keys}
                useInputSearch={false}
                applyFilter={applyFilter}
            />
            <div className={styles.list}>
                {isFilterButtons?.map((item: ListMenu, index: number) => {
                    const currentMode = extractValueFromString(item.icon.source, 'showMode');
                    const currentQuantity = quantity.find((i: QuantityFinancial) => i.mode === currentMode) as QuantityFinancial;

                    return (
                        <SimpleButton
                            key={item.idForm}
                            column={getCountColumn(isFilterButtons.length, index)}
                            handler={() => handleFilterButton(item)}
                        >
                            <div className={styles.listItem}>
                                <span className={styles.listTitle}>{currentQuantity?.quan}</span>
                                <span className={styles.listText}>{item.name}</span>
                            </div>
                        </SimpleButton>
                    );
                })}
                {otherButtons?.map((item: ListMenu) => {
                    return (
                        <SimpleButton
                            key={item.idForm}
                            column={2}
                            handler={() => handleOtherButton(item)}
                        >
                            <div className={styles.listItem}>
                                {
                                    item.icon.type === 'iconsvg'
                                        ? <ChartUser color={themColors[theme].color} width={34} height={34}
                                                     fileName={item.icon.source as string}/>
                                        : (
                                            <ImageLayout
                                                src={item.icon.source}
                                                imageWidth={32}
                                                imageHeight={32}
                                                containerWidth={32}
                                                containerHeight={32}
                                            />
                                        )
                                }
                                <span className={styles.listText}>{item.name}</span>
                            </div>
                        </SimpleButton>
                    );
                })}
            </div>
            <Footer/>

        </MainLayout>
    );
};
