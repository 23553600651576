import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useLazyGetByRangeQuery} from './store/monitoring.api';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import styles from './styles.module.scss';
import Footer from '../../components/footer';
import {MonitoringDataCard, MonitoringFilter} from './components';
import {useMonitoringControl} from '../../hooks';
import AppLoader from '../../components/appLoader';

export const MonitoringByRange = () => {
    const navigate = useNavigate();

    const [getByRange, {data, isLoading}] = useLazyGetByRangeQuery();
    const {applyFilter, clearFilter} = useMonitoringControl({

        onFetch: ({params}) => getByRange({params})
    });

    return (
        <MainLayout>
            <Header breadCrumbs={'Мониторинг - Выручка за период'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={false}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            {
                isLoading
                    ? <AppLoader isFull={false}/>
                    : (
                        <div className={styles.list}>
                            <MonitoringDataCard column={2} num={data?.paySum} text={'Выручка'}/>
                            <MonitoringDataCard column={2} num={data?.trx} text={'Kол-во чеков (TRX)'}/>
                            <MonitoringDataCard column={2} num={data?.at} text={'Средний чек(AT)'}/>
                            <MonitoringDataCard column={2} num={data?.adt} text={'Чеков/день/тчк (ADT)'}/>
                            <MonitoringDataCard column={2} num={data?.upt} text={'Товары/чек (UPT)'}/>
                            <MonitoringDataCard column={2} num={data?.uptm} text={'Тов+модиф/чек (UPTM)'}/>
                        </div>
                    )
            }

            <Footer/>
        </MainLayout>
    );
};
