import {api} from '../../../store/api';
import {DynamicsDate, DynamicsObj, DynamicTable, ReviewChart, ReviewTable} from './types';

function swapHeadElements(data: any) {
    // Проверяем, что массив достаточно длинный
    if (data.table && data.table.head && data.table.head.length > 3) {
        // Меняем местами элементы с индексами 1 и 3
        [data.table.head[1], data.table.head[3]] = [data.table.head[3], data.table.head[1]];
    }
    return data.table.head;
}

export const reviewApi = api.injectEndpoints({
    endpoints: build => ({
        getChartRatings: build.query<{ res: ReviewChart[], negative: number }, { params: string }>({
            query: ({params}) => ({
                url: `/feedbacks/pieChartRatings.php?${params}`
            }),
            keepUnusedDataFor: 0,
            transformResponse: (data: any) => {
                return {res: data.res, negative: data.negative};
            }
        }),
        getTableSource: build.query<ReviewTable, { params: string }>({
            query: ({params}) => ({
                url: `/feedbacks/tableBySource.php?${params}`
            }),
            keepUnusedDataFor: 0,
            transformResponse: (data: any) => {
                const head = data.table.head.map((i: any) => ({title: i.title}));
                const body = data.table.body;
                return {
                    head,
                    body
                };
            }
        }),
        getTableTag: build.query<ReviewTable, { params: string }>({
            query: ({params}) => ({
                url: `/feedbacks/tableByTagsRating.php?${params}`
            }),
            keepUnusedDataFor: 0,
            transformResponse: (data: any) => {
                const head = data.table.head.map((i: any) => ({title: i.title}));
                const body = data.table.body;
                return {
                    head,
                    body
                };
            }
        }),
        getReviewByObj: build.query<DynamicsDate[], { params: string }>({
            query: ({params}) => ({
                url: `/feedbacks/graphByDate.php?${params}`
            }),
            transformResponse: (data: any) => {
                return data.graphData;
            }
        }),
        getReviewObjGroup: build.query<DynamicsDate[][], { params: string }>({
            query: ({params}) => ({
                url: `/feedbacks/graphByDateObjects.php?${params}`
            }),
            transformResponse: (data: any) => {
                return data.graphData.map((i: DynamicsObj) => i.data);
            }
        }),
        getReviewObjTable: build.query<DynamicTable, { params: string }>({
            query: ({params}) => ({
                url: `/feedbacks/tableByObjects.php?${params}`
            }),
            transformResponse: (data: any) => {
                const head = data.table.head.map((i: any) => ({title: i.title}));
                const body = data.table.body;
                return {
                    head: head,
                    body
                };
            }
        }),
    }), overrideExisting: true
});

export const {
    useLazyGetChartRatingsQuery,
    useLazyGetTableSourceQuery,
    useLazyGetTableTagQuery,
    useLazyGetReviewByObjQuery,
    useLazyGetReviewObjGroupQuery,
    useLazyGetReviewObjTableQuery
} = reviewApi;
