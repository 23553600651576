import React, {useId, useLayoutEffect} from 'react';
import {createChart} from '../lib';
import {ChatOpt} from '../store/types';

interface ChartProps {
    chart: ChatOpt[];
    handleClickByLegend: (name: string, value: string) => void;
}

export const Chart = ({chart, handleClickByLegend}: ChartProps) => {
    const chartId = useId();

    useLayoutEffect(() => {

        const root = createChart(chartId, chart, handleClickByLegend);

        return () => {
            root.dispose();
        };
    }, [chart]);

    return <div id={chartId} style={{width: '100%', height: '300px'}}/>;
};
