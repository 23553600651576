import React, {useEffect} from 'react';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MainLayout} from '../../layouts';
import {ReviewFilter, ReviewObjectLocation} from './store/types';
import {createD3Chart} from './lib';
import {
    useLazyGetReviewByObjQuery,
    useLazyGetReviewObjGroupQuery,
    useLazyGetReviewObjTableQuery
} from './store/review.api';
import {useAppSelector} from '../../hooks/app.hooks';
import {TableObjects} from './components';
import styles from './style.module.scss';
import {Spin} from 'antd';

function getParams(filter: ReviewFilter[], state: ReviewObjectLocation): string {
    let params = filter.map(item => `${item.type}=${item.value}`).join('&');
    if (state.provider) {
        params = params + `&FRatings=${state.grade}&provider=${state.provider}`;
    }
    if (state.tag) {
        params = params + `&FRatings=${state.grade}&tag=${state.tag}`;
    }

    return params;
}

export const ReviewByObjects = () => {
    const navigate = useNavigate();
    const location: Location = useLocation();
    const {state} = location as Location & { state: ReviewObjectLocation };
    const {filter} = useAppSelector(state => state.reviews);

    const [getByObj, {isFetching: loadCommon}] = useLazyGetReviewByObjQuery();
    const [getByObjGroup, {isFetching: loadGroup}] = useLazyGetReviewObjGroupQuery();
    const [getTable, {data: table}] = useLazyGetReviewObjTableQuery();

    useEffect(() => {
        const params = getParams(filter, state);
        getByObj({params})
            .unwrap()
            .then((data) => {
                setTimeout(() => createD3Chart({datasets: data, isMultiple: false}), 200);
            });
        getTable({params});
    }, []);

    const handleSelectSome = (ids: number[]) => {
        const params = getParams(filter, state);
        if (ids.length > 0) {
            const joinIds = ids.join(',');
            getByObjGroup({params: `${params}&FObjects=${joinIds}`})
                .unwrap()
                .then((data) => {
                    setTimeout(() => createD3Chart({datasets: data, isMultiple: true}), 200);
                });
        } else {
            getByObj({params})
                .unwrap()
                .then((data) => {
                    setTimeout(() => createD3Chart({datasets: data, isMultiple: false}), 200);
                });
        }
    };


    return (
        <MainLayout>
            <Header breadCrumbs={'Cводные итоги'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            {
                loadCommon || loadGroup
                    ? <div className={styles.spiner}><Spin percent={'auto'} size={'small'}/></div>
                    : <>
                        <div className={styles.chart} id={'dynamic'}></div>
                    </>
            }

            <div className={styles.wrapper}>
                <TableObjects table={table} selectItems={handleSelectSome}/>
            </div>
        </MainLayout>
    );
};
