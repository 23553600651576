import React, {useState} from 'react';
import styles from '../style.module.scss';
import {DynamicTable} from '../store/types';
import classNames from 'classnames';

interface TableObjectsProps {
    table?: DynamicTable;
    selectItems: (ids: number[]) => void;
}

export const TableObjects = ({table, selectItems}: TableObjectsProps) => {
    const [selected, setSelected] = useState<number[]>([]);

    const handleSelect = (ID_Obj: number) => {
        selected.includes(ID_Obj)
            ? setSelected(prevState => {
                const newState = prevState.filter(s => s !== ID_Obj);
                if (newState.length === 0) {
                    selectItems(newState);
                }
                return newState;
            })
            : setSelected([...selected, ID_Obj]);
    };
    return (
        <>
            <div className={styles.tableTooltip}>
                <button
                    className={styles.tableTooltipButton}
                    disabled={selected.length === 0}
                    onClick={() => selectItems(selected)}
                >Посмотреть несколько
                </button>
            </div>
            <div className={styles.table}>
                <div className={styles.tableHead}>
                    {table?.head.map((head, index) => (
                        <div key={index} className={index === 3
                            ? classNames(styles.tableItem, styles.tableItemLast)
                            : styles.tableItem}>
                            <p className={styles.tableTitle}>{index === 3 ? '' : head.title}</p>
                        </div>
                    ))}
                </div>
                <>
                    {table?.body.map((item, index) => {

                        const isPositive = Math.sign(item.dynamic.sortValue) === 1;
                        return (
                            <div className={styles.tableRow} key={index} onClick={() => {
                                if (index > 0) {
                                    handleSelect(item.ID_Obj);
                                }
                            }}>
                                <div className={styles.tableItem}>
                                    {index > 0
                                        ? <input
                                            type={'checkbox'}
                                            readOnly={true}
                                            checked={selected.includes(item.ID_Obj)}
                                        />
                                        : <input
                                            type={'checkbox'}
                                            readOnly={true}
                                            checked={selected.length === 0}
                                        />}
                                    <p className={styles.tableTitle}>{item.ObjName}</p>
                                </div>
                                <div className={styles.tableItem}>
                                    <p className={styles.tableTitle}>{item.cnt.display}</p>
                                </div>
                                <div className={styles.tableItem}>
                                    <p className={styles.tableTitle}>{item.cntLastPeriod.display}</p>
                                </div>
                                <div className={classNames(styles.tableItem, styles.tableItemLast)}>
                                    <p className={styles.tableTitle}
                                       style={{color: isPositive ? '#000000' : '#C32B46', fontSize: '10px'}}
                                    >{item.dynamic.display}</p>
                                </div>
                            </div>
                        );
                    })}
                </>
            </div>
        </>
    );
};
