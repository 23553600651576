import React from 'react';
import {ReviewObjectLocation, ReviewTable} from '../store/types';
import styles from '../style.module.scss';
import {TableReviewRow} from './TableReviewRow';

interface TableReviewProps {
    table: ReviewTable | undefined;
    handleSelect: (state: ReviewObjectLocation) => void;
}

export const TableReview = ({table, handleSelect}: TableReviewProps) => {

    return (
        <div className={styles.table}>
            <div className={styles.tableHead}>
                {table?.head.map((head, index) => {
                    if (index > 2) return null;
                    return (
                        <div key={index} className={styles.tableItem}>
                            <p className={styles.tableTitle}>{head.title}</p>
                        </div>
                    );
                })}
            </div>
            <>
                {table?.body.map((item, index) =>
                    <TableReviewRow key={index} item={item} handleSelect={handleSelect} index={index}/>)}
            </>
        </div>
    );
};
