import React, {useEffect, useState} from 'react';
import {SelectFormRow} from '../../../components/selectFormRow';
import {formatDate, fromJsonToUrlencoded} from '../../../helpers';
import styles from '../style.module.scss';
import {DatePicker, DatePickerProps, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {Input} from '../../../fields/input';
import {TextareaControl} from '../../../fields/textarea';
import ReactFileReader from 'react-file-reader';
import {ImageLayout} from '../../../layouts';
import {Button} from '../../../fields/button';
import {IFillableForm, ITask, ITaskReq, TaskType} from '../store/types';
import {toast} from 'react-toastify';
import {IPermission, Option, Status} from '../../../store/types';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    useActionTaskMutation,
    useGetPriorityQuery,
    useGetSolverMutation,
    useGetTaskFilesMutation,
    useGetThemesMutation,
    useGetUserObjectsMutation,
} from '../store/task.api';
import {useGetTaskStatusesMutation} from '../../../store/api';
import {useUploadFileMutation} from '../../quiz/store/quiz.api';
import {FileFromBack} from '../../quiz/store/types';
import {useAppDispatch, useAppSelector} from '../../../hooks/app.hooks';
import * as yup from 'yup';
import AppLoader from '../../../components/appLoader';
import {TreeNode} from '../../../components/taskFilter/store/type';
import {excludeStatusForChangeTask} from '../TaskCreate';
import {Statuses} from '../components';
import {useSendMessageMutation} from '../../../components/chat/store/chat.api';
import {useLocation} from 'react-router-dom';
import {RenderAttachment} from '../../../components/renderAttachment';
import {FinancialFile} from '../../financial/state/types';
import {setCreatedTask} from '../store/task.slice';
import {PlusIcon} from '../../../assets/svg';
import {themColors} from '../../../hooks/useChangeTheme';

const plusIcon = require('../../../assets/images/plus.png');
const photoIcon = require('../../../assets/images/photo.png');

export const userObjParams = {
    ShowComp: 'NULL',
    flagSHID: false
};

interface ITaskCreate extends ITaskReq {
    DateDeadline?: string;
    ID_App?: string;
}


const schemaForm = yup.object().shape({
    ID_State: yup.string(),
    ID_Sect: yup.string(),
    ID_Obj: yup.string().required('Обязательно к заполнению'),
    ID_Solver: yup.string(),
    ID_Priority: yup.string(),
    Name: yup.string().required('Обязательно к заполнению'),
    Rem: yup.string().required('Обязательно к заполнению'),
    DateDeadline: yup.string()
});

function findNodeByValue(tree: TreeNode[], targetValue: string): TreeNode | undefined {
    // Проходим по каждому элементу массива
    for (const node of tree) {
        // Проверяем текущий узел
        if (node.value === targetValue) {
            return node;
        }
        // Если есть дочерние узлы, рекурсивно вызываем эту же функцию для них
        if (node.children) {
            const result = findNodeByValue(node.children, targetValue);
            if (result) {
                return result;
            }
        }
    }
    // Если узел не найден, возвращаем undefined
    return undefined;
}

interface FormTaskProps {
    task: ITask | undefined,
    handleChangeStateModal: (state: boolean) => void;
    permission: IPermission;
    refetchTask: (ID: number) => void;
}


export const FormTask = (
    {
        task,
        handleChangeStateModal,
        permission,
        refetchTask
    }: FormTaskProps) => {
    const dispatch = useAppDispatch();
    const {state} = useLocation();
    const {id_con} = useAppSelector(state => state.auth.user);
    const {theme} = useAppSelector(state => state.app);
    const {data: priority} = useGetPriorityQuery();
    const [getObjects, {data: objects}] = useGetUserObjectsMutation({fixedCacheKey: 'objects'});
    const [getThemes, {data: themes}] = useGetThemesMutation();
    const [getSolvers, {data: solvers}] = useGetSolverMutation();
    const [getFiles] = useGetTaskFilesMutation();
    const [getStatuses, {data: stData}] = useGetTaskStatusesMutation({fixedCacheKey: 'statuses'});

    const {createdTask} = useAppSelector(state => state.taskSwitch);

    const [loader, setLoader] = useState<boolean>(true);
    const [currentFile, setCurrentFiles] = useState<FinancialFile | File>();

    const [numPages, setNumPages] = useState<number>(1);
    const [showFile, setShowFile] = useState<boolean>(false);

    const [uploadFile, {isLoading}] = useUploadFileMutation();
    const [saveTask] = useActionTaskMutation();
    const [sendMessage] = useSendMessageMutation();
    const [backFiles, setFiles] = useState<FileFromBack[]>([]);
    const [uploadedCount, setUploadedCount] = useState<string[]>([]);

    useEffect(() => {
        if (task) {
            getStatuses();

            Promise.all([
                getObjects({body: fromJsonToUrlencoded({data: userObjParams})}).unwrap(),
                getThemes({body: fromJsonToUrlencoded({data: {ID_Sect: task?.ID_Sect}})}).unwrap(),
                getSolvers({body: fromJsonToUrlencoded({data: {FAppSects: task?.ID_Sect}})}).unwrap(),
                getFiles({body: fromJsonToUrlencoded({data: {ID_App: task?.ID}})}).unwrap()
            ])
                .then(([objectsRes, themesRes, solversRes, filesRes]) => {
                    // Если в location есть объект state это значит что мы пришли редактировать
                    // делаем запросы за всеми options и ставим дефолтные значения.
                    // Знаешь как оптимизировать? Оптимизируй.
                    const initValue = objectsRes.listObject.find(i => i.value === String(task?.ID_Obj)) as Option;
                    setValue('ID_Obj', initValue?.value);

                    const p = priority?.list.find(p => p.value === String(task?.ID_Priority));
                    setValue('ID_Priority', p?.value);

                    setValue('Name', task?.Name);
                    setValue('Rem', task?.Rem);

                    const initThemeValue = findNodeByValue(themesRes?.treeList as TreeNode[], String(task?.ID_Sect));
                    setValue('ID_Sect', initThemeValue?.value as string);

                    const initSolverValue = solversRes.list.find(i => i.value === String(task?.ID_Solver)) as Option;
                    setValue('ID_Solver', initSolverValue?.value as string);

                    if (task?.DateDeadline) {
                        const date = new Date(task?.DateDeadline?.date?.replace(' ', 'T'));
                        setValue('DateDeadline', formatDate({date, type: 'YYYY-MM-DD'}));
                    }

                    setUploadedCount(filesRes.files.map(i => i.id));

                    setFiles(filesRes.files);
                })
                .catch((e) => {
                    toast(`Ошибка при получении списка фильтров. Попробуйте позже`, {
                        type: 'error',
                        position: 'top-center'
                    });
                })
                .finally(() => setLoader(false));
            // if (!state?.isNew) {
            //
            // } else {
            //     getObjects({body: fromJsonToUrlencoded({data: userObjParams})})
            //         .unwrap()
            //         .then(() => setLoader(false));
            // }

        } else {
            if (state?.claim) {
                setLoader(true);
                Promise.all([
                    getObjects({body: fromJsonToUrlencoded({data: userObjParams})}).unwrap(),
                    getThemes({body: fromJsonToUrlencoded({data: {ID_Sect: 134}})}).unwrap(),
                ]).then(([objectsRes, themesRes]) => {
                    setValue('ID_Sect', '134');
                }).catch((e) => console.log('e', e))
                    .finally(() => setLoader(false));
            } else {
                getObjects({body: fromJsonToUrlencoded({data: userObjParams})})
                    .unwrap()
                    .then(() => setLoader(false));
            }
        }

    }, [task]);

    useEffect(() => {

        if (createdTask !== null) {
            setLoader(true);

            Promise.all(createdTask?.ID_Sect ? [
                getObjects({body: fromJsonToUrlencoded({data: userObjParams})}).unwrap(),
                getThemes({body: fromJsonToUrlencoded({data: {ID_Sect: createdTask?.ID_Obj}})}).unwrap(),
                getSolvers({body: fromJsonToUrlencoded({data: {FAppSects: createdTask?.ID_Sect}})}).unwrap(),
            ] : [
                getObjects({body: fromJsonToUrlencoded({data: userObjParams})}).unwrap(),
                getThemes({body: fromJsonToUrlencoded({data: {ID_Sect: createdTask?.ID_Obj}})}).unwrap(),
            ])
                .then(([objectsRes, themesRes, solversRes]) => {

                    const initValue = objectsRes.listObject.find(i => i.value === String(createdTask?.ID_Obj)) as Option;
                    setValue('ID_Obj', initValue?.value);

                    const p = priority?.list.find(p => p.value === String(createdTask?.ID_Priority));
                    setValue('ID_Priority', p?.value);

                    setValue('Name', createdTask?.Name);
                    setValue('Rem', createdTask?.Rem);

                    if (themesRes) {
                        const initThemeValue = findNodeByValue(themesRes?.treeList as TreeNode[], String(createdTask?.ID_Sect));
                        setValue('ID_Sect', initThemeValue?.value as string);
                    }

                    if (solversRes) {
                        const initSolverValue = solversRes.list.find(i => i.value === String(createdTask?.ID_Solver)) as Option;
                        setValue('ID_Solver', initSolverValue?.value as string);
                    }


                    if (createdTask?.DateDeadline) {
                        setValue('DateDeadline', createdTask?.DateDeadline);
                    }

                })
                .catch((e) => {
                    toast(`Ошибка при получении списка фильтров. Попробуйте позже`, {
                        type: 'error',
                        position: 'top-center'
                    });
                })
                .finally(() => setLoader(false));
        }
    }, []);

    useEffect(() => {
        return () => {
            setFiles([]);
        };
    }, []);

    const {control, setValue, watch, handleSubmit, clearErrors, formState: {errors}} = useForm<IFillableForm>({
        mode: 'onChange',
        resolver: yupResolver(schemaForm),
    });

    const handleChangeField = (key: string, value: string) => {
        setValue(key as keyof IFillableForm, value);
        dispatch(setCreatedTask(createdTask !== null ? {...createdTask, [key]: value} : {[key]: value}));
    };

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        //setValue('DateDeadline', String(dateString));
        handleChangeField('DateDeadline', String(dateString));
        clearErrors('DateDeadline');
    };


    const handleFiles = (files: any) => {
        const {fileList, base64} = files;
        const formData = new FormData();
        formData.append('load_file', fileList[0], fileList[0].origName);
        formData.append('type', 'task');

        uploadFile({file: formData})
            .unwrap()
            .then(async (result) => {
                await sessionStorage.removeItem('isFile');
                setFiles([...backFiles, {
                    id: result.id,
                    fileName: result.fileName,
                    origName: result.origName,
                    base64
                }]);
                toast(`Загружен файл: ${result.origName}`, {
                    type: 'info',
                    position: 'top-center'
                });

            }).catch(() => {
            toast.error('Ошибка загрузки', {
                position: 'top-center'
            });
        });
    };

    const onSubmit = async (values: IFillableForm) => {

        const data: ITaskCreate = {
            Type: task?.ID ? TaskType.Update : TaskType.Insert,
            ID_Type: '2',
            SelectRow: '1',
            ID_Form: '115',
            ID_State: '1',
            ID_Sect: values.ID_Sect,
            ID_Obj: values.ID_Obj,
            ID_Priority: values.ID_Priority,
            ID_Solver: values.ID_Solver,
            Name: values.Name,
            Rem: values.Rem
        };

        if (values?.DateDeadline) {
            data.DateDeadline = values.DateDeadline;
        }

        if (task?.ID) {
            data.ID_App = String(task.ID);
        }
        setLoader(true);

        saveTask({
            data: fromJsonToUrlencoded({data})
        })
            .unwrap()
            .then((data) => {
                if (data.status) {
                    //  handleSetNumTask(data?.info as ITask)
                    if (state?.claim) {
                        if (backFiles.length > uploadedCount.length) {
                            const newFiles = backFiles.filter(i => !uploadedCount.includes(i.id));
                            const dataForm = new FormData();
                            dataForm.append('Type', 'Insert');
                            dataForm.append('ID_App', `${data?.info?.ID}`);
                            dataForm.append('ID_Form', '115');
                            dataForm.append('SelectRow', '1');

                            for (let i = 0; i < newFiles.length; i++) {
                                dataForm.append(`files[${i}]`, JSON.stringify(newFiles[i]));
                            }
                            sendMessage({id_con, body: dataForm})
                                .unwrap()
                                .then(() => {
                                    handleChangeStateModal(true);
                                }).catch((e: any) => {
                                toast(`Ошибка при добавлении файлов к ${values.Name}`, {
                                    type: 'error',
                                    position: 'top-center'
                                });
                            });
                        } else {
                            handleChangeStateModal(true);
                        }
                    } else {
                        if (backFiles.length > uploadedCount.length) {
                            const dataForm = new FormData();
                            dataForm.append('Type', 'Insert');
                            dataForm.append('ID_App', `${data?.info?.ID}`);
                            dataForm.append('ID_Form', '115');
                            dataForm.append('SelectRow', '1');

                            const newFiles = backFiles.filter(i => !uploadedCount.includes(i.id));

                            for (let i = 0; i < newFiles.length; i++) {
                                dataForm.append(`files[${i}]`, JSON.stringify(newFiles[i]));
                            }
                            sendMessage({id_con, body: dataForm})
                                .unwrap()
                                .then(() => {
                                    handleChangeStateModal(true);
                                }).catch((e: any) => {
                                toast(`Ошибка при добавлении файлов к ${values.Name}`, {
                                    type: 'error',
                                    position: 'top-center'
                                });
                            });
                        } else {
                            handleChangeStateModal(true);
                        }
                    }

                } else {
                    toast(`Ошибка: ${data?.message}`, {
                        type: 'error',
                        position: 'top-center'
                    });
                }
                dispatch(setCreatedTask(null));
                refetchTask(data?.info?.ID);
            }).catch((err) => {
            toast(`Ошибка при сохранении ${values.Name}`, {
                type: 'error',
                position: 'top-center'
            });
        }).finally(() => setLoader(false));
    };

    const handleChangeStatus = (item: Status) => {
        const data = {
            Type: TaskType.Update,
            ID_Type: '2',
            SelectRow: '1',
            ID_Form: '115',
            ID_Obj: task?.ID_Obj,
            ID_App: task?.ID,
            ID_State: String(item.id)
        };
        saveTask({
            data: fromJsonToUrlencoded({data})
        }).unwrap()
            .then(({status, info}) => {
                if (status) {
                    toast(`Статус задачи изменен на ${item.name}`, {
                        type: 'success',
                        position: 'top-center'
                    });
                    refetchTask(info.ID);
                } else {
                    toast(`Ошибка`, {
                        type: 'error',
                        position: 'top-center'
                    });
                }

            });
    };

    const handleShowFile = (file: any) => {
        setShowFile(true);
        setCurrentFiles(file);
    };

    const isEdit = !excludeStatusForChangeTask.includes(Number(task?.ID_State));
    const showButtonSave = (permission?.CanOtherEdit === 1 && isEdit) || (!task?.ID && isEdit);
    return loader ? <AppLoader isFull={false}/> : (
        <>
            <SelectFormRow
                options={objects?.listObject}
                label={'Объект'}
                error={errors['ID_Obj'] ? errors['ID_Obj']?.message : undefined}
                isTreeOptions={false}
                disabled={permission?.CanOtherEdit === 0}
                defaultValue={watch('ID_Obj') ?? ''}
                handleSelect={(value) => {
                    //setValue('ID_Obj', value);
                    handleChangeField('ID_Obj', value);
                    clearErrors('ID_Obj');
                    if (!state?.claim) {
                        getThemes({body: fromJsonToUrlencoded({data: {ID_Sect: value}})});
                    }
                }}
            />
            <SelectFormRow
                options={themes?.treeList}
                label={'Тема'}
                isTreeOptions={true}
                disabled={permission?.CanOtherEdit === 0 || !themes?.treeList || state?.claim}
                error={errors['ID_Sect'] ? errors['ID_Sect']?.message : undefined}
                defaultValue={watch('ID_Sect') ?? ''}
                handleSelect={(value) => {
                    //  setValue('ID_Sect', value);
                    handleChangeField('ID_Sect', value);
                    clearErrors('ID_Sect');
                    getSolvers({body: fromJsonToUrlencoded({data: {FAppSects: value}})});
                }}
            />
            {!state?.claim
                ? (
                    <>
                        <SelectFormRow
                            options={solvers?.list}
                            label={'Ответств.'}
                            isTreeOptions={false}
                            disabled={permission?.CanOtherEdit === 0 || !solvers?.list}
                            error={errors['ID_Solver'] ? errors['ID_Solver']?.message : undefined}
                            defaultValue={watch('ID_Solver') ?? ''}
                            handleSelect={(value) => {
                                //  setValue('ID_Solver', value);
                                handleChangeField('ID_Solver', value);
                            }}
                        />
                        <SelectFormRow
                            options={priority?.list}
                            label={'Важность'}
                            isTreeOptions={false}
                            disabled={permission?.CanOtherEdit === 0}
                            defaultValue={watch('ID_Priority') ?? ''}
                            handleSelect={(value) => {
                                // setValue('ID_Priority', value)
                                handleChangeField('ID_Priority', value);
                            }}
                        />
                        <div className={styles.taskFormRow}>
                            <div className={styles.taskFormRowLabelBox}>
                                <label className={styles.taskLabel}>Срок</label>
                            </div>
                            <div className={styles.taskFormRowInputBox}>
                                <DatePicker
                                    className={errors['DateDeadline']?.message
                                        ? classNames(styles.select, styles.selectError)
                                        : styles.select}
                                    placeholder={''}
                                    disabled={permission?.CanOtherEdit === 0}
                                    value={watch('DateDeadline') ? dayjs(watch('DateDeadline')) : undefined}
                                    onChange={onChange}/>
                            </div>
                        </div>
                    </>
                )
                : null}

            <div className={styles.space}/>
            {state && !state?.claim && !state?.isNew ?
                <Statuses
                    items={stData?.list ?? []}
                    currentStatus={task?.ID_State ?? 0}
                    disabled={permission?.CanOtherEdit === 0}
                    handleClick={handleChangeStatus}
                />
                : null}
            <div className={styles.space}/>
            <Input
                type={'text'}
                fieldName={'Name'}
                placeholder={'Название задачи'}
                control={control}
                disabled={permission?.CanOtherEdit === 0}
                defaultValue={watch('Name')}
                onChange={value => {
                    // setValue('Name', String(value));
                    handleChangeField('Name', String(value));
                    clearErrors('Name');
                }}
            />
            <div className={styles.space}/>
            <TextareaControl
                fieldName={'Rem'}
                control={control}
                placeholder={'Описание задачи'}
                disabled={permission?.CanOtherEdit === 0}
                defaultValue={watch('Rem')}
                onChange={value => {
                    //setValue('Rem', String(value));
                    handleChangeField('Rem', String(value));
                    clearErrors('Rem');
                }}
            />
            <div className={styles.fileList}>
                {permission?.CanOtherEdit === 1 || !task
                    ? <ReactFileReader
                        fileTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                        multiple={false}
                        base64
                        handleFiles={(files: File[]) => {
                            handleFiles(files);
                        }}>
                        <div className={styles.wrapReaderButton}>
                            <button
                                className={styles.fileReaderButton}
                                onClick={
                                    async (e) => {
                                        await sessionStorage.setItem('isFile', 'OK');
                                    }
                                }>
                                {isLoading ?
                                    <Spin indicator={<LoadingOutlined style={{fontSize: 14}} spin/>}/>
                                    : <PlusIcon color={themColors[theme]?.color ?? '#000000'}/>
                                }
                            </button>
                            <span className={styles.minText}>Доб. файл</span>
                        </div>
                    </ReactFileReader> : null}
                {backFiles.length > 0
                    ? <div className={styles.list}>
                        {backFiles.map((file, index) => (
                            <div key={index} className={styles.wrapReaderButton} onClick={() => handleShowFile(file)}>
                                <button key={`${index}-${file.fileName}`}
                                        className={styles.fileReaderButton}>
                                    <ImageLayout
                                        src={photoIcon}
                                        imageWidth={36}
                                        imageHeight={36}
                                        containerWidth={36}
                                        containerHeight={36}/>
                                </button>
                                <span className={styles.minText}>{file.fileName}</span>
                            </div>
                        ))}
                    </div>
                    : null}
                {showFile && task ?
                    <div className={styles.modalFile}>
                    <span
                        className={styles.close}
                        onClick={() => setShowFile(false)}
                    />
                        <div className={styles.modalFileContent}>
                            <RenderAttachment
                                currentFile={currentFile}
                                pageNumber={numPages}
                                setNumPages={setNumPages}
                            />
                        </div>

                    </div> : null}
            </div>
            {showButtonSave
                ? <div className={styles.bottomWrapper}>
                    <Button
                        text={'Сохранить'}
                        textStyleType={'semiBold'}
                        style={{
                            fontSize: '16px'
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit(onSubmit)();
                        }}
                    />
                </div> : null}
        </>
    );
};
