import React, {useEffect} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import {useGetCallCountQuery} from './store/call.api';
import AppLoader from '../../components/appLoader';
import styles from './style.module.scss';
import SimpleButton from '../../components/simpleButton';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {defaultCallFilter, setChangeDate, setFilterCall, setObj} from './store/call.slice';
import {FilterCall} from './store/types';
import dayjs from 'dayjs';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import {DatePicker, TimeRangePickerProps} from 'antd';
import {useGetFilterObjMutation} from '../../components/financialFilter/store/financial.filter.api';
import {fromJsonToUrlencoded} from '../../helpers';
import {SelectFormRow} from '../../components/selectFormRow';
import {Option} from '../../store/types';
import {Button} from '../../fields/button';

dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.locale('ru');

const {RangePicker} = DatePicker;

interface CreateParams {
    FObjects: string | null;
    dateStart: string;
    dateFinish: string;
    filter: FilterCall;
}

const presets: TimeRangePickerProps['presets'] = [
    {label: 'Сегодня', value: [dayjs().startOf('day'), dayjs().endOf('day')]},
    {label: 'Вчера', value: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')]},
    {label: 'Эта нед', value: [dayjs().startOf('week'), dayjs().endOf('week')]},
    {label: 'Пред нед', value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().startOf('week').add(-1, 'd')]},
    {label: 'Этот мес', value: [dayjs().startOf('month'), dayjs().endOf('month')]},
    {label: 'Пред мес', value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().startOf('month').add(-1, 'd')]},
];

export function createParams({FObjects, dateFinish, dateStart, filter}: CreateParams): string {
    let params = `Start=${dateStart}&Finish=${dateFinish}`;
    if (FObjects !== '0') {
        params = `${params}&FObjects=${FObjects}`;
    }

    if (!filter.all) {
        const statuses = {
            noanswer: filter.noanswer,
            success: filter.success,
            missed: filter.missed,
        };

        const types = {
            in: filter.in,
            out: filter.out
        };
        if (Object.values(statuses).some(value => value)) {
            const FStatuses = Object.entries(statuses)
                .filter(([key, value]) => value)
                .map(([key]) => key).join(',');
            params = `${params}&FStatuses=${FStatuses}`;
        }
        if (Object.values(types).some(value => value)) {
            const FTypes = Object.entries(types)
                .filter(([key, value]) => value)
                .map(([key]) => key).join(',');
            params = `${params}&FTypes=${FTypes}`;
        }
    }
    return params;
}

export const PhoneCallCounts = () => {

    // const {makeCall} = useCall();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [getFilter, {data: objects}] = useGetFilterObjMutation({fixedCacheKey: 'objectFilter'});

    const {filter, dateStart, dateFinish, FObjects} = useAppSelector(state => state.call);
    const params = createParams({filter, dateStart, dateFinish, FObjects});

    const {data, isLoading} = useGetCallCountQuery(
        {params}
    );

    useEffect(() => {
        getFilter({body: fromJsonToUrlencoded({data: {ShowComp: 'NULL', flagSHID: false}})});
    }, []);


    const handleSelect = (key: keyof FilterCall) => {
        if (key === 'all') {
            return dispatch(setFilterCall(defaultCallFilter));
        }
        dispatch(setFilterCall({...filter, all: false, [key]: !filter[key]}));
    };

    const handleChangeDate = (dateStrings: [string, string]) => {
        const formattedDates = dateStrings.map(date =>
            (dayjs(date, 'D MMM, YYYY').format('YYYY-MM-DD'))
        );
        const dateStart = formattedDates[0];
        const dateFinish = formattedDates[1];

        dispatch(setChangeDate({dateStart, dateFinish}));
    };

    const handleSelectObj = (value: string) => {
        dispatch(setObj(value));
    };

    // const handleCall = () => {
    //     makeCall(targetVal);
    // };
    return (
        <MainLayout>
            <Header breadCrumbs={'Количество звонков'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            {isLoading
                ? <AppLoader isFull={false}/>
                : (
                    <div className={'container'}>
                        <RangePicker
                            dropdownClassName="custom-range-picker-dropdown"
                            className={styles.inputRange}
                            locale={ru_RU}
                            format="D MMM, YYYY"
                            presets={presets}
                            value={[dayjs(dateStart, 'YYYY.MM.DD'), dayjs(dateFinish, 'YYYY.MM.DD')]}
                            onChange={(dates, dateStrings) => handleChangeDate(dateStrings)}
                            separator="-"
                            allowClear={false}
                        />
                        <SelectFormRow
                            options={objects ? [{
                                label: 'Все объекты',
                                value: '0'
                            }, ...objects.listObject as Option[]] : []}
                            label={'Объекты'}
                            disabled={false}
                            isTreeOptions={false}
                            defaultValue={FObjects ?? ''}
                            handleSelect={handleSelectObj}
                        />
                        <div className={styles.wrapper}>
                            <SimpleButton
                                column={3}
                                isActive={filter.all}
                                handler={() => handleSelect('all')}>
                                <div className={styles.box}>
                                    <span className={styles.number}>{data?.result.all}</span>
                                    <span className={styles.text}>Все</span>
                                </div>

                            </SimpleButton>
                            <SimpleButton isActive={filter.in} column={3} handler={() => handleSelect('in')}>
                                <div className={styles.box}>
                                    <span className={styles.number}>{data?.resultType.in}</span>
                                    <span className={styles.text}>Исходящие</span>
                                </div>
                            </SimpleButton>
                            <SimpleButton isActive={filter.out} column={3} handler={() => handleSelect('out')}>
                                <div className={styles.box}>
                                    <span className={styles.number}>{data?.resultType.out}</span>
                                    <span className={styles.text}>Входящие</span>
                                </div>
                            </SimpleButton>
                            <SimpleButton isActive={filter.success} column={3} handler={() => handleSelect('success')}>
                                <div className={styles.box}>
                                    <span className={styles.number}>{data?.resultStatus.success}</span>
                                    <span className={styles.text}>Отвеченные</span>
                                </div>
                            </SimpleButton>
                            <SimpleButton
                                isActive={filter.noanswer}
                                column={3}
                                handler={() => handleSelect('noanswer')}>
                                <div className={styles.box}>
                                    <span className={styles.number}>{data?.resultStatus.noanswer}</span>
                                    <span className={styles.text}>Нет ответа</span>
                                </div>
                            </SimpleButton>
                            <SimpleButton isActive={filter.missed} column={3} handler={() => handleSelect('missed')}>
                                <div className={styles.box}>
                                    <span className={styles.number}>{data?.resultStatus.missed}</span>
                                    <span className={styles.text}>Пропущенные</span>
                                </div>
                            </SimpleButton>
                        </div>
                        <div className={styles.send}>
                            <Button
                                text={'Показать звонки'}
                                textStyleType={'semiBold'} style={{}}
                                onClick={() => navigate('/calls')}
                            />
                        </div>
                        {/*<div className={styles.send} style={{marginTop: '20px'}}>*/}

                        {/*    <CallComponent/>*/}

                        {/*    /!*<input type={'number'} inputMode="numeric" value={targetVal}*!/*/}
                        {/*    /!*       onChange={e => setTargetVal(e.target.value)}/>*!/*/}
                        {/*    /!*<button onClick={handleCall}>Позвонить</button>*!/*/}
                        {/*</div>*/}
                    </div>)
            }

        </MainLayout>
    );
};
