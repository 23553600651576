import React, {useState} from 'react';
import {Point} from '../store/types';
import {Placemark} from '@pbe/react-yandex-maps';
import styles from '../styles.module.scss';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from '../../../hooks/app.hooks';
import {OrderInfo} from '../../monitoringOrder/store/types';
import {useLazyGetOrderInfoQuery} from '../../monitoringOrder/store/order.api';
import {CloseIcon} from '../../../assets/svg/CloseIcon';
import {formatNumber} from '../../../helpers';
import {replaceText} from '../../monitoringOrder/tabs/Common';

const iconMapMarkGray = require('../../../assets/images/map/mapMarkGray.png');
const iconTransparent = require('../../../assets/images/map/transparent.png');

interface RenderOrderPointProps {
    item: Point;
    ymaps: any;
}

interface OrderBalloonProps {
    item: OrderInfo;
    onClose: () => void;
    onMore: () => void;
}

const OrderBalloon = ({item, onClose, onMore}: OrderBalloonProps) => {
    return (
        <div className={styles.balloon}>
            <button onClick={onClose} className={styles.balloonClose}>
                <CloseIcon/>
            </button>
            <div className={styles.balloonHeader}>
                <p className={styles.balloonText}>Заказ №{item.orderInfo.number}</p>
                <button onClick={onMore} className={styles.balloonMore}>Подробнее</button>
            </div>
            <div className={styles.balloonSeparator}/>
            <p className={styles.balloonStrong}>Статус {item.orderInfo.status}</p>
            <div className={styles.balloonSeparator}/>
            <p className={styles.balloonText}>Создан {item.orderInfo.DateCreated}</p>
            <div className={styles.balloonSeparator}/>
            <p className={styles.balloonText}>Закрыт {item.orderInfo.DateClosed ?? '-'}</p>
            <div className={styles.balloonSeparator}/>
            <p className={styles.balloonStrong}>Сумма {formatNumber(item.orderInfo.sum)}</p>
            <div className={styles.balloonSeparator}/>
            <p className={styles.balloonText}>Примечание {replaceText(item.orderRem)}</p>
            <div className={styles.balloonSeparator}/>
            <p className={styles.balloonText}>Клиент {item.customer.name}</p>
            <p className={styles.balloonText}>тел: {item.customer.phone}</p>
            <div className={styles.balloonSeparator}/>
            <p className={styles.balloonText}>тел: {item.customer.phone}</p>
            <div className={styles.balloonSeparator}/>
            <p className={styles.balloonText}>Адрес: {item.address['1']}</p>
            <div className={styles.balloonSeparator}/>
            <p className={styles.balloonText}>{item.objectInfo.PlaceType}: {item.objectInfo.Name}</p>
            <div className={styles.balloonSeparator}/>

            <p className={styles.balloonText}>Курьер {item.courier.name ?? '-'}</p>
            <p className={styles.balloonText}>тел: {item.courier.phone ?? '-'}</p>
            <div className={styles.balloonSeparator}/>
        </div>
    );
};


export const RenderOrderPoint = ({item, ymaps}: RenderOrderPointProps) => {
    const navigate = useNavigate();
    const [getOrderInfo] = useLazyGetOrderInfoQuery();
    const {order} = useAppSelector(state => state.order);
    const [show, setShow] = useState<boolean>(false);

    return (
        <>
            <Placemark
                geometry={[item.lat, item.lon]}
                options={{
                    iconImageHref: '',
                    iconOffset: [-25, -55],
                    iconImageSize: [30, 30],
                    iconLayout: ymaps.templateLayoutFactory.createClass(
                        `<div style="padding: 5px; 
width: fit-content; 
display: flex; 
align-items: center; 
border: 1px solid red;
border-radius: 4px;
height: 15px; 
background-color: #ffffff;">
<p style="font-size: 10px; white-space: nowrap;">${item.status}</p>
</div>`,
                        {
                            build: function () {
                                // Вызываем метод build родительского класса
                                this.constructor.superclass.build.call(this);
                                // Получаем ссылку на контейнер метки
                                const element = this.getParentElement();
                                // Если элемент существует, скрываем стандартную иконку
                                if (element) {
                                    const icon = element.querySelector('.ymaps-icon');
                                    if (icon) {
                                        icon.style.display = 'none';
                                    }
                                }
                            }
                        }
                    ),
                }}
                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
            />
            <Placemark
                geometry={[item.lat, item.lon]}
                options={{
                    iconLayout: 'default#image',
                    iconImageHref: iconMapMarkGray,
                    iconOffset: [0, 0],
                    iconImageSize: [30, 30],

                }}
                // modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                onClick={() => {
                    getOrderInfo({ID_Obj: item.idObj, orderId: item.idOrder})
                        .unwrap()
                        .then((data) => {
                            setShow(true);
                        });
                }}
            />
            {show && order ? (
                <div className={styles.balloonWrapper}>
                    <OrderBalloon
                        item={order}
                        onMore={() => navigate('/order-details')}
                        onClose={() => setShow(false)}
                    />
                </div>
            ) : null}
        </>
    );
};
