import React from 'react';
import styles from '../style.module.scss';
import {ReviewBody, ReviewObjectLocation} from '../store/types';
import {useCollapse} from 'react-collapsed';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';

interface TableReviewRowProps {
    item: ReviewBody;
    index: number;
    handleSelect: (state: ReviewObjectLocation) => void;
}

export const TableReviewRow = (
    {
        item,
        index,
        handleSelect
    }: TableReviewRowProps
) => {
    const navigate = useNavigate();
    const {getCollapseProps, getToggleProps, setExpanded, isExpanded} = useCollapse();

    const handlePress = (grade: string) => {
        if ('tag' in item) {
            navigate('/review-objects', {state: {grade, tag: item.tag}});
        } else {
            navigate('/review-objects', {state: {grade, provider: item.name}});
        }
        //  handleSelect({grade, provider: item.name});
    };

    return (
        <>
            <div
                className={index % 2 !== 0 ? styles.tableRowCollapse : classNames(styles.tableRowCollapse, styles.tableRowCollapseGrey)}
                {...getToggleProps({
                    onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                })}>
                <div className={styles.tableItem} onClick={() => setExpanded(!isExpanded)}>
                    <p className={styles.tableTitle}>{item.name}</p>
                    <span className={isExpanded ? classNames(styles.arrowUp, styles.arrowUpUpped) : styles.arrowUp}/>
                </div>
                <div className={styles.tableItem}>
                    <p className={styles.tableTitle}>{item.all.display}</p>
                </div>
                <div className={styles.tableItem}>
                    <p className={styles.tableTitle}>{item.allPercent.display}</p>
                </div>
            </div>
            <div {...getCollapseProps()}>
                <div className={styles.tableRowCollapseInner} onClick={() => handlePress('1')}>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>1*</p>
                    </div>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>{item.rating_1.display}</p>
                    </div>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>{item.rating_1Percent.display}</p>
                    </div>

                </div>
                <div className={styles.tableRowCollapseInner} onClick={() => handlePress('2')}>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>2*</p>
                    </div>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>{item.rating_2.display}</p>
                    </div>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>{item.rating_2Percent.display}</p>
                    </div>
                </div>
                <div className={styles.tableRowCollapseInner} onClick={() => handlePress('3')}>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>3*</p>
                    </div>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>{item.rating_3.display}</p>
                    </div>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>{item.rating_3Percent.display}</p>
                    </div>
                </div>
                <div className={styles.tableRowCollapseInner} onClick={() => handlePress('4')}>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>4*</p>
                    </div>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>{item.rating_4.display}</p>
                    </div>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>{item.rating_4Percent.display}</p>
                    </div>
                </div>
                <div className={styles.tableRowCollapseInner} onClick={() => handlePress('5')}>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>5*</p>
                    </div>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>{item.rating_5.display}</p>
                    </div>
                    <div className={styles.tableItem}>
                        <p className={styles.tableTitle}>{item.rating_5Percent.display}</p>
                    </div>
                </div>
            </div>
        </>
    );
};
