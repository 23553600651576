import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAppSelector} from '../hooks/app.hooks';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from '../ErrorBoundary';

export const PrivateRouteMain = () => {
    const {isAuth, user} = useAppSelector(state => state.auth);

    return isAuth && user.notificationToken
        ? (
            <ErrorBoundary>
                <Outlet/>
            </ErrorBoundary>
        ) : <Navigate to={'/login'} replace={true}/>;
};
