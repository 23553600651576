import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useLocation, useNavigate} from 'react-router-dom';
import {useGetPdfQuery} from '../quiz/store/quiz.api';
import AppLoader from '../../components/appLoader';
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';
import {formatNumber} from '../../helpers';
import classNames from 'classnames';
import {Controls, SortControl} from './components';
import {useShare} from '../../hooks';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {setSortColumn} from './store/table.slice';

interface ResHead {
    key: string;
    name: string;
    type: string;
    width: number;
}

interface ResBody {
    ID_Obj: number;
    ObjFormat: number | null;
    ObjName: string;
    avgCheck: number;
    breakEven: number;
    checksCnt: number;
    pastInterval0: number;
    pastInterval1: number;
    pastInterval2: number;
    pastInterval3: number;
    paySum: number;
    plan: number;
    time: string | null;
}

function formatText(text: string | number | null, key: string): string {
    if (text === null) {
        return '';
    }
    if (typeof text === 'string') {
        return text;
    }

    if (key === 'checksCnt') {
        return formatNumber(text);
    }
    return formatNumber(text).split('.')[0];
}

function sortByKey<T>(arr: T[], key: keyof T, direction: 'asc' | 'desc'): T[] {

    return arr.slice().sort((a: T, b: T) => {
        if (direction === 'asc') {
            if (a[key] > b[key]) {
                return -1;
            }
            if (a[key] < b[key]) {
                return 1;
            }
        }
        if (direction === 'desc') {
            if (a[key] > b[key]) {
                return 1;
            }
            if (a[key] < b[key]) {
                return -1;
            }
        }

        return 0;
    });
}

export const TableView = () => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {data, isLoading} = useGetPdfQuery({path: state?.path}, {
        skip: !state?.path
    });
    const {sortColumn} = useAppSelector(state => state.table);
    const {isFetching, handleShare} = useShare({path: state?.path, screenViewName: state?.screenViewName});
    const [positionIndex, setPosIndex] = useState<number>(0);

    const [table, setTable] = useState<{ resBody: ResBody[], resHead: ResHead[] } | undefined>();

    useEffect(() => {
        if (data) {
            setTable(data);
        }
    }, [data]);

    const handleSort = (key: string, direction: 'asc' | 'desc') => {
        dispatch(setSortColumn({key, direction: direction === 'asc' ? 'desc' : 'asc'}));
    };

    useEffect(() => {
        if (table && table.resBody) {
            const sortArray = sortByKey(table?.resBody as ResBody[], sortColumn.key as keyof ResBody, sortColumn.direction);
            setTable({resBody: sortArray, resHead: table?.resHead as ResHead[]});
        }
    }, [sortColumn]);

    return (
        <MainLayout>
            <Header breadCrumbs={'Таблица'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            {isLoading
                ? <AppLoader isFull={false}/>
                : (
                    <div className={styles.wrapper}>
                        <TransformWrapper
                            disabled={positionIndex === 0}
                        >
                            <>
                                <Controls
                                    handleShare={handleShare}
                                    loader={isFetching}
                                    positionIndex={positionIndex}
                                    setPosIndex={setPosIndex}
                                />
                                <TransformComponent>

                                    <div className={styles.table}>
                                        <div className={styles.tableHeader}>
                                            {table?.resHead.map((item: ResHead, index: number) => {

                                                if (item.key === 'ID_Obj') {
                                                    return '';
                                                }
                                                return (
                                                    <div className={styles.tableHead}>
                                                        <div className={styles.tableHeadInner}>
                                                            <span className={styles.tableHeadText}>{item.name}</span>
                                                        </div>
                                                        {index > 2
                                                            ? <SortControl
                                                                isActive={sortColumn.key === item.key}
                                                                direction={sortColumn?.direction}
                                                                onPress={(isActive) => handleSort(item.key, isActive)}
                                                            />
                                                            : null}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {table?.resBody.map((item: ResBody) => {
                                            return (
                                                <div className={styles.tableRow}>
                                                    {table?.resHead.map((h: ResHead) => {
                                                        const text = h.key === 'ID_Obj' ? null : item[`${h.key}` as keyof ResBody];

                                                        if (text === null && h.key === 'ID_Obj') {
                                                            return '';
                                                        }
                                                        return (
                                                            <div className={typeof text === 'number' ?
                                                                classNames(styles.tableRowItem, styles.tableRowItemNum)
                                                                : styles.tableRowItem}>
                                                                <span
                                                                    className={
                                                                        styles.tableRowText
                                                                    }>
                                                                    {formatText(text, h.key)}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>);
                                        })}
                                    </div>

                                </TransformComponent>
                            </>

                        </TransformWrapper>
                    </div>
                )}

        </MainLayout>

    );
};
